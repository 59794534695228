export const imageBaseURL = process.env.REACT_APP_IMAGE_BASE_URL;

function createCaseClick() {}

export const assignBlockAction = {
  label: "Assign Block",
  icon: `${imageBaseURL}/Svg/actionsIcon/assignBlockAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverAssignBlock.png`,
};

export const reAssignAction = {
  label: "Re-Assign",
  icon: `${imageBaseURL}/Svg/actionsIcon/ReAssignAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverReAssign.svg`,
};

export const uploadDocumentAction = {
  label: "Upload Documents",
  icon: `${imageBaseURL}/Svg/actionsIcon/upload-documents-action.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverUploadDoc.svg`,
};

export const caseDescriptionAction = {
  label: "Case Description",
  icon: `${imageBaseURL}/Svg/actionsIcon/case-description-action.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverCaseDes.svg`,
};

export const gradeCaseAction = {
  label: "Grade Case",
  icon: `${imageBaseURL}/Svg/actionsIcon/gradeCaseAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverGradeCase.svg`,
};

export const investigationDetailsAction = {
  label: "Investigation Details",
  icon: `${imageBaseURL}/Svg/actionsIcon/investigationDetailsActions.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverInvestigationDetails.svg`,
};

export const submitFinalAction = {
  label: "Submit Final",
  icon: `${imageBaseURL}/Svg/actionsIcon/submitFinalAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverSubmitFinal.svg`,
};

export const holdUnholdAction = {
  label: "Hold / Unhold",
  icon: `${imageBaseURL}/Svg/actionsIcon/holdUnholdAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverHoldUnholdIcon.svg`,
};

export const peerReviewAction = {
  label: "Peer Review",
  icon: `${imageBaseURL}/Svg/actionsIcon/peerReviewIcon.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverPeerReviewIcon.svg`,
};

export const recommendedAction = {
  label: "Recommendation",
  icon: `${imageBaseURL}/Svg/actionsIcon/submitFinalAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverSubmitFinal.svg`,
};

export const vendorAssesmentAction = {
  label: "Vendor Assessment",
  icon: `${imageBaseURL}/Svg/actionsIcon/vendorAssesmentAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverVendorAssesment.svg`,
};

export const flagAction = {
  label: "Flag",
  icon: `${imageBaseURL}/Svg/actionsIcon/flagAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/HoverFlag.svg`,
};

export const invoiceApproveAction = {
  label: "Approve",
  icon: `${imageBaseURL}/Svg/actionsIcon/approveAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverApprove.svg`,
};

export const invoiceRejectAction = {
  label: "Reject",
  icon: `${imageBaseURL}/Svg/actionsIcon/rejectAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverReject.svg`,
};

export const fileHandoverDateAction = {
  label: "File Handover Date",
  icon: `${imageBaseURL}/Svg/actionsIcon/FileHandoverAction.svg`,
  hoverIcon: `${imageBaseURL}/Svg/hoverActionsIcon/hoverFileHandover.svg`,
};

export const openCaseActionList = [
  assignBlockAction,
  reAssignAction,
  uploadDocumentAction,
  caseDescriptionAction,
  investigationDetailsAction,
  gradeCaseAction,
  submitFinalAction,
  holdUnholdAction,
  peerReviewAction,
];

export const holdCaseActionList = [holdUnholdAction];

export const BSopenCaseActionList = [
  uploadDocumentAction,
  caseDescriptionAction,
  gradeCaseAction,
  submitFinalAction,
];

export const BSTLOpenCaseActionList = [
  uploadDocumentAction,
  caseDescriptionAction,
  gradeCaseAction,
  submitFinalAction,
  reAssignAction,
];

export const allocatedCaseActionList = [
  uploadDocumentAction,
  caseDescriptionAction,
];

export const qcCaseActionList = [uploadDocumentAction, caseDescriptionAction];

export const queryCaseActionList = [];

export const recommendedCaseActionList = [];

export const submittedCaseActionList = [
  recommendedAction,
  uploadDocumentAction,
];

export const rejectedCaseActionList = [
  uploadDocumentAction,
  caseDescriptionAction,
  investigationDetailsAction,
  gradeCaseAction,
  peerReviewAction,
  submitFinalAction,
];

export const approvalActionList = [
  vendorAssesmentAction,
  gradeCaseAction,
  submitFinalAction,
  uploadDocumentAction,
  flagAction,
  peerReviewAction,
];

export const invoiceApprovalActionList = [
  invoiceApproveAction,
  invoiceRejectAction,
];

export const defaultCaseActionList = [invoiceApproveAction];

export const uploadOptions = [
  "Crash / Spot photo",
  "Estimate",
  "Claim form",
  "Policy / Cover note",
  "RC book",
  "Driving licence",
  "KYC document",
  "Bills",
  "Discharged card",
  "Investigation reports",
  "Query reply",
  "Internal documents",
  "Bank NEFT details",
  "Case reports",
  "Diagnostic reports",
  "FIR",
  "Others",
];

// file handover allowed upload types
export const allowedFileFormats = [
  "png",
  "jpg",
  "jpeg",
  "pdf",
  "heic",
  "heif",
  "webp",
  "docx",
  "tiff",
  "xlsx",
];

//assign block options
export const options = [1, 2, 3, 4, 5];

// case managment filter
export const statusFilterItems = [
  { label: "Doctor does not exist" },
  { label: "Not Recommended" },
  { label: "Closed Case" },
  { label: "Un-Assigned Case" },
  { label: "IM Assigned" },
];

export const textBoxArray = [
  {
    id: "basic",
    label: "Basic Details",
    name: "Basic Details",
  },
  {
    id: "policy",
    label: "Policy Details",
    name: "Policy Details",
  },
  {
    id: "proposal",
    label: "Proposal Details",
    name: "Proposal Details",
  },
  // {
  //   id: "dispatch",
  //   label: "Dispatch Details",
  //   name: "Dispatch Details",
  // },
  {
    id: "claim",
    label: "Claim Details",
    name: "Claim Details",
  },
  {
    id: "risk",
    label: "Risk Details",
    name: "Risk Details",
  },
  {
    id: "endorsement",
    label: "Endorsement Details",
    name: "Endorsement Details",
  },
  {
    id: "external",
    label: "CHASE Smart",
    name: "CHASE Smart",
  },
];
export const openCaseRecommendationList = [
  "Police Complaint",
  "Send to claims",
  "De-Empanel Hospital",
  "Negative Garage",
  "Blacklist Investigator",
];

export const motorTriggerTable = {
  column: [
    {
      id: "srNo",
      label: "Sr. No",
      align: "left",
    },
    {
      id: "RULES",
      label: "Rule",
      align: "left",
    },
    {
      id: "DESCRIPTION",
      label: "Description",
      align: "left",
    },
    {
      id: "SEVERITY",
      label: "Severity",
      align: "left",
    },
  ],
  row: [
    {
      srNo: 1,
      rule: "Rule 25",
      description:
        "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Qorem ipsum dolor sit amet, consectetur adipiscing elit.",
      severity: "Dummy Data",
    },
    {
      srNo: 2,
      rule: "Rule 26",
      description:
        "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Qorem ipsum dolor sit amet, consectetur adipiscing elit.",
      severity: "Dummy Data",
    },
    {
      srNo: 3,
      rule: "Rule 27",
      description:
        "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Qorem ipsum dolor sit amet, consectetur adipiscing elit.",
      severity: "Dummy Data",
    },
    {
      srNo: 4,
      rule: "Rule 28",
      description:
        "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Qorem ipsum dolor sit amet, consectetur adipiscing elit.",
      severity: "Dummy Data",
    },
    {
      srNo: 5,
      rule: "Rule 29",
      description:
        "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Qorem ipsum dolor sit amet, consectetur adipiscing elit.",
      severity: "Dummy Data",
    },
  ],
};

export const segmentInfoDetails = {
  "Vendor Details": {
    column: [
      {
        id: "srNo",
        label: "Sr. No",
        align: "left",
        headerPaddingLeft: "12px",
        width: "70px",
      },
      {
        id: "vendor_names",
        label: "Vendor Names:",
        align: "left",
      },
      {
        id: "case_status",
        label: "Case Status:",
        align: "center",
      },
      // {
      //   id: "re_assign",
      //   label: " ",
      //   align: "center",
      //   cursor: "pointer",
      // },
      { id: "withdraw", label: " ", align: "center", cursor: "pointer" },
      {
        id: "re_assign",
        label: " ",
        align: "center",
        cursor: "pointer",
      },
      {
        id: "manual_trigger_remark",
        label: "Manual Trigger Remark",
        align: "center",
        cursor: "pointer",
      },
      {
        id: "view_blocks",
        label: " ",
        align: "right",
        color: "#1395CC",
        cursor: "pointer",
      },
    ],
  },
  "Basic Details": {
    tabComponent: [
      {
        label: "Policy Number",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Policy Start Date",
        value: "last week",
      },
      {
        label: "Policy Status",
        value: "Active",
      },
      {
        label: "Claim Number",
        value: "CASE/879/797",
      },
      {
        label: "Policy End Date",
        value: "last week",
      },
      {
        label: "Policy Issue Date",
        value: "last week",
      },
    ],
  },
  "Policy Details": {
    isDocuments: false,
    documentDetails: [
      {
        name: "Policy Copy.pdf",
        size: "2.4kb",
      },
      {
        name: "Endorsement Copy.pdf",
        size: "2.4kb",
      },
      {
        name: "Proposal Form.pdf",
        size: "2.4kb",
      },
      {
        name: "Others",
        size: "1mb",
      },
    ],
    tabComponent: [
      {
        label: "Policy Number",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Total Premium",
        value: "last week",
      },
      {
        label: "Policy Type",
        value: "Active",
      },
      {
        label: "Policy Start Date",
        value: "CASE/879/797",
      },
      {
        label: "Policy End Date",
        value: "last week",
      },
      {
        label: "Policy inception date",
        value: "last week",
      },
      {
        label: "Policy Number",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Total Premium",
        value: "last week",
      },
      {
        label: "LOB",
        value: "Active",
      },
      {
        label: "Product Name",
        value: "CASE/879/797",
      },
      {
        label: "Sub Product Plan Name",
        value: "last week",
      },
      {
        label: "Policy Term/Tenure",
        value: "last week",
      },
      {
        label: "Hypothecation With",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Total Sum Insured",
        value: "last week",
      },
      {
        label: "CB Amount",
        value: "Active",
      },
      {
        label: "NCB Amount",
        value: "CASE/879/797",
      },
      {
        label: "Insured Details",
        value: "last week",
      },
      {
        label: "Customer Reg. Mobile No.",
        value: "last week",
      },
      {
        label: "Customer Reg. Email ID",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Customer Address",
        value: "last week",
      },
      {
        label: "Customer State",
        value: "Active",
      },
      {
        label: "Customer City",
        value: "CASE/879/797",
      },
      {
        label: "Customer Pincode",
        value: "last week",
      },
      {
        label: "Nominee Details",
        value: "last week",
      },
    ],
  },
  "Proposal Details": {
    tabComponent: [
      {
        label: "Previous Policy Details",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Previous Policy Number",
        value: "last week",
      },
      {
        label: "Start Date",
        value: "Active",
      },
      {
        label: "End Date",
        value: "CASE/879/797",
      },
      {
        label: "Inspection Status",
        value: "last week",
      },
      {
        label: "Survey Report",
        value: "last week",
      },
      {
        label: "Break In (PI Report)",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Inspection  - Photo Image",
        value: "last week",
      },
      {
        label: "PROPOSAL NO",
        value: "Active",
      },
      {
        label: "PROPOSAL NO",
        value: "CASE/879/797",
      },
      {
        label: "Diagnostic Reports",
        value: "last week",
      },
      {
        label: "UW REMARKS",
        value: "last week",
      },
      {
        label: "PROPOSAL_STATUS",
        value: "case/2022-10-13/55113",
      },
      {
        label: "INTERMEDIARY_NAME",
        value: "last week",
      },
      {
        label: "INTERMEDIARY_Code",
        value: "Active",
      },
      {
        label: "PRIMARY_SALES_MANAGER",
        value: "CASE/879/797",
      },
      {
        label: "SECONDARY_SALES_MANAGER",
        value: "last week",
      },
    ],
  },
  "Claim Details": {
    row: [
      {
        srNo: "1",
        claim_number: "case/2022-10-13/55113",
        lossDate: "last week",
        registrationDate: "Active",
        claimRegisteredBy: "CASE/879/797",
        blank: "View Details",
        blank2: "Create Case",
      },
      {
        srNo: "2",
        claim_number: "case/2022-10-13/55113",
        lossDate: "last week",
        registrationDate: "Active",
        claimRegisteredBy: "CASE/879/797",
        blank: "View Details",
        blank2: "Create Case",
      },
      {
        srNo: "3",
        claim_number: "case/2022-10-13/55113",
        lossDate: "last week",
        registrationDate: "Active",
        claimRegisteredBy: "CASE/879/797",
        blank: "View Details",
        blank2: "Create Case",
      },
      {
        srNo: "4",
        claim_number: "case/2022-10-13/55113",
        lossDate: "last week",
        registrationDate: "Active",
        claimRegisteredBy: "CASE/879/797",
        blank: "View Details",
        blank2: "Create Case",
      },
      {
        srNo: "5",
        claim_number: "case/2022-10-13/55113",
        lossDate: "last week",
        registrationDate: "Active",
        claimRegisteredBy: "CASE/879/797",
        blank: "View Details",
        blank2: "Create Case",
      },
    ],
    column: [
      {
        id: "srNo",
        label: "Sr. No",
        align: "left",
        headerPaddingLeft: "12px",
      },
      {
        id: "Claim_Number",
        label: "Claim Number:",
        align: "left",
      },
      {
        id: "Loss Date",
        label: "Loss Date:",
        align: "left",
      },
      {
        id: "Registration Date",
        label: "Registration Date:",
        align: "left",
      },
      {
        id: "Claim Registered by",
        label: "Claim Registered By:",
        align: "left",
      },
      {
        id: "view_details",
        label: " ",
        align: "right",
        color: "#1395CC",
        cursor: "pointer",
      },
      {
        id: "create_case",
        fontSize: " ",
        align: "left",
        color: "#1395CC",
        cursor: "pointer",
        onClick: createCaseClick,
      },
    ],
    tableGridVal: [
      {
        label: "Policy Number",
        value: "case/23-23/23300",
      },
      {
        label: "policy start date",
        value: "last week",
      },
      {
        label: "Policy status",
        value: "Active",
      },
      {
        label: "claim number",
        value: "case/34/45/900",
      },
      {
        label: "policy end date",
        value: "last week",
      },
      {
        label: "policy issue date",
        value: "last week",
      },
      {
        label: "Policy Number",
        value: "case/23-23/23300",
      },
      {
        label: "policy start date",
        value: "last week",
      },
      {
        label: "Policy status",
        value: "Active",
      },
      {
        label: "claim number",
        value: "case/34/45/900",
      },
      {
        label: "policy end date",
        value: "last week",
      },
      {
        label: "policy issue date",
        value: "last week",
      },
      {
        label: "Policy Number",
        value: "case/23-23/23300",
      },
      {
        label: "policy start date",
        value: "last week",
      },
      {
        label: "Policy status",
        value: "Active",
      },
      {
        label: "claim number",
        value: "case/34/45/900",
      },
      {
        label: "policy end date",
        value: "last week",
      },
      {
        label: "policy issue date",
        value: "last week",
      },
    ],
  },
  "Risk Details": {
    tabComponent: [
      {
        label: "Deductible",
        value: "case/2022-10-13/55113",
      },
      {
        label: "RTO Location",
        value: "last week",
      },
      {
        label: "Seating Capacity",
        value: "Active",
      },
      {
        label: "Vehicle  Model – Variant",
        value: "CASE/879/797",
      },
      {
        label: "Manufacturing Year",
        value: "last week",
      },
      {
        label: "CC Capacity",
        value: "last week",
      },
      {
        label: "Vehicle Make",
        value: "case/2022-10-13/55113",
      },
      {
        label: "IDV",
        value: "last week",
      },
      {
        label: "Name of Proposer",
        value: "Active",
      },
      {
        label: "Customer Name",
        value: "CASE/879/797",
      },
      {
        label: "Name of All Insured",
        value: "last week",
      },
      {
        label: "Type of Insured",
        value: "last week",
      },
      {
        label: "Relationship",
        value: "case/2022-10-13/55113",
      },
      {
        label: "DOB",
        value: "last week",
      },
      {
        label: "Sum Insured",
        value: "Active",
      },
      {
        label: "Coverage  Name",
        value: "CASE/879/797",
      },
      {
        label: "Vehicle Chasis No",
        value: "last week",
      },
      {
        label: "Registration Date",
        value: "last week",
      },
    ],
  },
  "Endorsement Details": {
    row: [
      {
        srNo: "1",
        endorsementNo: "case/2022-10-13/55113",
        typeOfEndorsement: "last week",
        transactionDate: "Active",
        premiumAmt: "CASE/879/797",
        blank: "View Details",
      },
      {
        srNo: "2",
        endorsementNo: "case/2022-10-13/55113",
        typeOfEndorsement: "last week",
        transactionDate: "Active",
        premiumAmt: "CASE/879/797",
        blank: "View Details",
      },
      {
        srNo: "3",
        endorsementNo: "case/2022-10-13/55113",
        typeOfEndorsement: "last week",
        transactionDate: "Active",
        premiumAmt: "CASE/879/797",
        blank: "View Details",
      },
      {
        srNo: "4",
        endorsementNo: "case/2022-10-13/55113",
        typeOfEndorsement: "last week",
        transactionDate: "Active",
        premiumAmt: "CASE/879/797",
        blank: "View Details",
      },
      {
        srNo: "5",
        endorsementNo: "case/2022-10-13/55113",
        typeOfEndorsement: "last week",
        transactionDate: "Active",
        premiumAmt: "CASE/879/797",
        blank: "View Details",
      },
    ],
    column: [
      {
        id: "srNo",
        label: "Sr. No",
        align: "left",
        headerPaddingLeft: "12px",
      },
      {
        id: "endorsement_no",
        label: "Endorsement No:",
        align: "left",
      },
      {
        id: "type_of_endorsement",
        label: "Type Of Endorsement:",
        align: "left",
      },
      {
        id: "transaction_date",
        label: "Transaction Date:",
        align: "left",
      },
      {
        id: "premium_amt",
        label: "Premium Amt:",
        align: "left",
      },
      {
        id: "view_details",
        label: " ",
        align: "left",
        color: "#1395CC",
        cursor: "pointer",
      },
    ],
    tableGridVal: [
      {
        label: "Premium _ Non-premium bearing",
        value: "last week",
      },
      {
        label: "Endorsement Reason",
        value: "last week",
      },
    ],
  },
  "CHASE Smart": {
    isDocuments: false,
    documentDetails: [
      {
        name: "CIBIL Report.pdf",
        size: "2.4kb",
      },
    ],
    tabComponent: [
      {
        label: "Driver Extract & Validity",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Type of Licence",
        value: "last week",
      },
      {
        label: "DL Verification",
        value: "Active",
      },
      {
        label: "RC Ver. Permit Verification",
        value: "CASE/879/797",
      },
      {
        label: "Fitness Verification",
        value: "last week",
      },
      {
        label: "Insurance Details & Validity of Insurance",
        value: "last week",
      },
      {
        label: "Company Name",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Policy Number",
        value: "last week",
      },
      {
        label: "Category",
        value: "Active",
      },
      {
        label: "Pan Verification",
        value: "CASE/879/797",
      },
      {
        label: "Blacklist Status",
        value: "last week",
      },
      {
        label: "ITR Validation",
        value: "last week",
      },
      {
        label: "Customer PAN Number",
        value: "case/2022-10-13/55113",
      },
      {
        label: "Phone Number",
        value: "last week",
      },
      {
        label: "Customer Full Name",
        value: "Active",
      },
      {
        label: "Address",
        value: "CASE/879/797",
      },
      {
        label: "Voter ID",
        value: "last week",
      },
      {
        label: "CRIF Report",
        value: "last week",
      },
      {
        label: "CB Amount",
        value: "last week",
      },
      {
        label: "Trace Lost Vehicle",
        value: "Active",
      },
      {
        label: "Flag in CIMA",
        value: "CASE/879/797",
      },
    ],
  },
};

export const downloadSupportedTypes = ["avi", "m4a"];
export const actionlabels = {
  "Assign Block": "Assign Block",
  "Case Description": "Case description",
  "Peer Review": "Peer Review",
  "File Handover date": "File Handover Date",
  "Investigation Details": "Investigation Details",
  "Hold / Unhold": "Reason to hold",
};
